
<template>
  <div>
    <form
      autocomplete="off"
      @submit.prevent="onSubmit"
    >
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('user.firstname')"
        label-for="firstname"
      >
        <b-form-input
          id="firstname"
          v-model="userInfo.name"
          readonly
        />
      </b-form-group>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('user.username')"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="userInfo.username"
          readonly
        />
      </b-form-group>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      userData: {
        email: '',
        phone_number: '',
        firstname: '',
        lastname: '',
        registration_url: '',
      },
      file: null,
      contactForm: {
        line_id: '',
        qr_code: '',
        message: '',
      },
      announcements: '',
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['adminRole', 'userInfo']),
    userId() {
      return this.userInfo.id
    },
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.setData()
      }
    },
  },
  methods: {
    ...mapActions(['updateProfile']),
    setData() {
      if (this.userId && this.userInfo) {
        const data = JSON.parse(JSON.stringify(this.userInfo))
        this.userData = {...data}
      } else {
        this.userData = {
          email: '',
          phone_number: '',
          firstname: '',
          lastname: '',
          registration_url: '',
        }
      }
    },
    onSubmit() {
      this.updateProfile(this.userData)
    },
  },
}
</script>
